@import url('https://fonts.googleapis.com/css?family=Lily+Script+One');
@import url("https://use.typekit.net/dke1zvm.css");

// $signatureFont: "Lily Script One", serif;
$signatureFont: turquoise, serif;
// $pink:  #e5204d;
$pink:  #E3007E;
// $white: rgb(237,237,237);
$white: #fff;
$black: #000;

.twoguysfromitalyglendale {
  font-family: $signatureFont;

  .cart.minimized {
    // background: rgba(237,237,237, 0.8);
    background: $white;
    border-bottom-color: rgba(0,0,0,0.3);
  }

  a.checkout-button.button {
    background: $pink;
  }

  .category__header {

    /* background: rgba(237,237,237, 0.4); */
  }
  .category-name .text {
    font-family: $signatureFont;
    font-size: 1.5em;
    background: none;
    color: black;
  }
  .category-name .text::before {

    content: "☙"
  }

  .landing-page-wrapper {
    h1, h2 {
      font-family: nautica, sans-serif;
      font-weight: 700;
      font-size: 2em;
      margin: 0;
      padding: 0.5em 0;

    }
    a {
      color: $pink;
    }
    .text {
      font-family: $signatureFont;
      background: url(https://afag.imgix.net/two-guys-from-italy-glendale/text-bg.png?auto=compress) repeat;
      padding-bottom: 0.5em;
      font-weight: 700;

    }
    .sections-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .section {


      width: 49%;

      box-sizing: border-box;
      margin-bottom: 1%;

      text-align: center;
      // padding: 0.5em;
      // padding: 1em 0;
      .content {
        background-size: cover;
        background-position: center;
        height: 400px;
        // background: #f9f9f9;
        border-radius: 10px;
        // padding: 1em 0;
      }

      @media screen and (max-width: 950px) {
        width: 99%;
      }
    }
  }
  .collage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .collage-element {
    // margin: 1em;
    width: 50%;
    img {
      max-width: 100%;
    }
  }
  .info {
    max-width: 100%;
    img {
      display: block;
    }
    .text-block {
      padding: 0.5em 0.5em;
      // background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1547498078/danstuff/background.png);
      font-family: $signatureFont;
    }
  }
  .slide {
    // border: 4px solid black;
    img {
      display: block;
    }
    // &:nth-of-type(2n) {
    //   transform: rotate(1deg)
    // }
    // &:nth-of-type(2n+1) {
    //   transform: rotate(-2deg)
    // }
  }

  a.order-online-button {
    // margin: 2em 0;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 0.5em 0.5em;
    background: $pink;
    font-family: $signatureFont;
    font-weight: 600;
    box-shadow: none;
    margin-right: 0.5em;
    margin: 0.5em;
    margin-left: 0;
    border-radius: 15px;
    box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523, 9px 9px #770523;
    transition: all 0.12s ease 0s;
    // position: absolute;
    // top: 0;
    // left: 0;
    &:hover {
      transform: translate(9px, 9px);
      box-shadow: none;
    }
    // border: 2px solid #222;
  }
  a.product-link {
    background: $pink;
    color: white;
    &:visited, &:hover {
      color: white;
    }
  }

  .order-benefits {
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 150px;
    /* max-height: 50vh; */
    background: url(https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order-benefits.home {
    background: url(https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress) no-repeat;
    background-size: cover;
    background-position: center 30%;
  }
  .order-benefits .text {
    background: rgba(255,255,255,0.8);
    padding: 0.5em 0.8em;
  }
  .order-benefits small {
    font-size: 0.8em;

  }
  .order-benefits h1 {
    font-weight: 900;
    font-size: 1.3em;
    /* background: white; */
  }
  .order-benefits p {
    /* background: white; */
  }


  .pages-nav {
    font-family: $signatureFont;
    background: $white;

    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    $borderWidth: 4px;
    $paddingY: 0.35em;
    text-decoration: none;
    color: $black;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid $white;
    &:hover, &.active {
      border-bottom: $borderWidth solid $pink;
    }
  }

  .full-menu-link-wrapper {
    padding: 1em 0;
    text-align: center;
    a.button {
      background: $pink;
      color: $white;
    }
  }

  .footer {
    font-family: $signatureFont;
    margin-top: 2em;
    // border-top: 4px solid $pink;

    // background: $white;
    // color: black;
    a.phone-link {
      color: $pink;
    }
  }

  .page {
    max-width: 640px;
    margin: 1em auto;
    padding: 1em 0.5em;
    h1, h2, h3 {
      font-weight: 600;
      margin: 0.5em 0;
      font-size: 1.3em;
      // text-align: center;
    }
  }
}
